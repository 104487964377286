<template>
    <div class="uv-page">
        <v-container fluid>
            <v-row justify="center">
                <v-col cols=3>
                    <v-card elevation="4" :to="{name: 'Reports'}">
                        <v-card-title class="justify-center">
                            <strong class="primary--text">Reports</strong>
                        </v-card-title>
                        <v-card-text>
                            <v-layout justify-center>
                                <v-icon size="248" color="primary">mdi-file-chart</v-icon>
                            </v-layout>
                            <v-layout justify-center>
                                Go to reports page
                            </v-layout>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols=3>
                    <v-card height="100%" elevation="4" href="https://www.esma.europa.eu" target="_blank">
                        <v-card-title class="justify-center">
                            <strong class="primary--text">ESMA Taxonomy</strong>
                        </v-card-title>
                        <v-card-text>
                            <v-layout justify-center>
                                <v-img contain height="248px" src="@/assets/esma_logo.svg" ></v-img>
                            </v-layout>
                            <v-layout justify-center>
                                <span class="justify">ESMA Taxonomies and Resources</span>
                            </v-layout>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
export default {
    name: "Dashboard",
    created: function() {
        this.$store.commit("setPageMenu", []);
    }
}
</script>